import Alpine from 'alpinejs';
if (import.meta.hot) {
    import.meta.hot.accept();
}

/* Import slider module js file */
import './modules/slider.js';
import MD5 from "crypto-js/md5";
import { setAlignFullScrollBarWidth } from './modules/utils.js';

/* GEKÅS OLD SCRIPT */

var itc_webp = {
    support: true,
    init: function () {
        var el = new Image();
        el.onload = el.onerror = function () {
            if (el.height == 2 && navigator.userAgent.search("Edg/") == -1 && !(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0)) return true;
            itc_webp.support = false;
            itc_webp.search();
            window.addEventListener('load', itc_webp.search);
            window.addEventListener('resize', itc_webp.search);
        }
        el.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    },
    search: function () {
        if (itc_webp.support) return;
        document.querySelectorAll('img[src*=".webp"]').forEach(function (el) {
            el.src = el.src.replace(/.webp$/, '');
        });

        document.querySelectorAll('div.fp-slide-image[style*=".webp"]').forEach(function (el) {
            el.setAttribute('style', el.getAttribute('style').replace('.webp', ''));
        });
    }
};
itc_webp.init();

window.itc_webp = itc_webp;


import '@/js/gekas/vendor/jquery-ui.p2h.min.js';
import '@/js/gekas/vendor/jquery-ui.multidatespicker.js';
import '@/js/gekas/vendor/jquery.magnific-popup.min.js';
import '@/js/gekas/vendor/jquery.validate.min.js';
import '@/js/gekas/vendor/formator.min.js';
import '@/js/gekas/vendor/jquery.cookie.js';
import '@/js/gekas/takeover/takeover.js';
// import '@/js/gekas/vendor/jquery-ui.autocomplete.min.js';

// import 'jquery-autocomplete';



/* ------------------------------------------------------------------------------------ */

Alpine.store('AllOpenSubMenus', {
    close() {
        document.getElementById('mobile-menu').querySelectorAll('[x-data]').forEach(el => {
            el._x_dataStack.forEach(i => {
                if (i.$data.submenuOpen) {
                    i.$data.submenuOpen = false;
                }
            });
        });
    }
})


Alpine.store('header', {
    toggleOverflowHidden() {
        const body = document.querySelector('body');
        body.style.overflow = body.style.overflow === 'hidden' ? 'auto' : 'hidden';
    }
})

Alpine.start()

import importObserver from './importObserver.js';

function main() {
    console.log('hello')
}



function minimizeFixedMenuOnScroll() {
    const isLoggedIn = document.body.classList.contains('logged-in');

    let prevScrollpos = window.scrollY;
    let header = document.getElementById("header");
    let headerHeight = !isLoggedIn ? 10 : 6;
    let headerTop = header.offsetTop;
    let headerBottom = headerTop + headerHeight;
    let headerMinimized = false;

    let spacerDiv = document.getElementById("menu-spacer");
    let spacerDivheight = window.innerWidth > 768 ? (!isLoggedIn ? 116 : 116) : (!isLoggedIn ? 100 : 86);

    window.onscroll = function () {

        let currentScrollPos = window.scrollY;

        if (prevScrollpos > currentScrollPos) {
            header.style.top = !isLoggedIn ? "0" : "32px";
            spacerDiv.style.height = spacerDivheight + "px";
        } else {
            header.style.top = "-" + headerHeight + "px";
            spacerDiv.style.height = (spacerDivheight - headerHeight) + "px";
        }

        if (currentScrollPos > headerBottom && !headerMinimized) {
            header.style.top = "-" + headerHeight + "px";
            spacerDiv.style.height = (spacerDivheight - headerHeight) + "px";
            headerMinimized = true;
        }

        if (currentScrollPos < headerBottom && headerMinimized) {
            headerMinimized = false;
        }

        prevScrollpos = currentScrollPos;
    }
}



document.addEventListener('DOMContentLoaded', () => {
    main()

    // Define the class you want to detect when removed
    const classToDetect = 'itc-takeover-visible';

    // Create a MutationObserver
    const observer = new MutationObserver(function (mutationsList) {
        mutationsList.forEach(function (mutation) {
            if (mutation.attributeName === 'class') {
                const oldClassList = mutation.oldValue.split(' ');
                const currentClassList = mutation.target.classList;

                if (oldClassList.includes(classToDetect) && !currentClassList.contains(classToDetect)) {
                    setAlignFullScrollBarWidth();
                }
            }
        });
    });

    if (typeof gekasAcf !== 'undefined') {

        function get_cookie(name) {
            if (document.cookie.indexOf(name + '=') == -1) {
                return false;
            }
            var parts = ("; " + document.cookie).split("; " + name + "=");
            if (parts.length == 2) var value = parts.pop().split(";").shift();
            return value;
        }


        if (((new Date().getTime() - (parseInt(get_cookie(MD5(gekasAcf.takeoverUrl).toString())) || 0)) < (86400 * 1000))) {
            setAlignFullScrollBarWidth();
            return;
        } else {

            var show = false;
            var state = ['show', 'hide'];

            for (var k in state) {
                var s = state[k];
                var ws = (gekasAcf.takeover.visibility[s].length > 0) ? gekasAcf.takeover.visibility[s].split(',') : '';

                for (var i = 0; i < ws.length; i++) {
                    if (ws[i].trim() == '/') {
                        if (window.location.pathname == '/') {
                            show = (s == 'show');
                            break;
                        }
                    }
                    else if (window.location.pathname == ws[i].trim()) {
                        show = (s == 'show');
                        break;
                    }
                }
                if (!show) break;
            }

            if (!show) {
                setAlignFullScrollBarWidth();
                return;
            }
        }

        observer.observe(document.body, { attributes: true, attributeOldValue: true, attributeFilter: ['class'] });
    } else {
        setAlignFullScrollBarWidth();
    }


    // minimizeFixedMenuOnScroll() // deactiated for now

    // importObserver
    // use only name of file without extension and ./, root is ./assets/js
    importObserver(document.querySelector('.wp-block-image'), 'hello');

    // if ($('.message-warning').length && !$.cookie('message-warning')) {
    //     $('.gk-messages').show();
    // }

    // $('.close-warning').click(function(){
    //     $(".message-warning").fadeOut(150, function() {
    //         $.cookie('message-warning', 'closed', {expires: 1, path: '/'});
    //     });
    //     return false;
    // });


    const scrollableContainer = document.querySelector('.filter-header');

    let isDragging = false;
    let startX;
    let scrollLeft;

    if (!scrollableContainer) return;

    scrollableContainer.addEventListener('mousedown', (e) => {
        isDragging = true;
        scrollableContainer.classList.add('active');
        startX = e.pageX - scrollableContainer.offsetLeft;
        scrollLeft = scrollableContainer.scrollLeft;
        e.preventDefault(); // Prevent text selection while dragging
    });

    scrollableContainer.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        const x = e.pageX - scrollableContainer.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the multiplier for speed
        scrollableContainer.scrollLeft = scrollLeft - walk;
    });

    scrollableContainer.addEventListener('mouseup', () => {
        isDragging = false;
        scrollableContainer.classList.remove('active');
    });

    scrollableContainer.addEventListener('mouseleave', () => {
        isDragging = false;
        scrollableContainer.classList.remove('active');
    });
})




/**
 * Manage global libraries like jQuery or THREE from the package.json file
 */
