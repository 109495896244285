// get styles
import "@/css/styles.css";

// get scripts
import "@/js/scripts.js";

// From old gekas
import "@/js/gekas/script.js";
import "@/js/gekas/p2h.script.js";
import "@/js/gekas/script.google-maps.js";

// Search
import "@/js/gekas/search.js";

window.$ = window.jQuery;
window.jQuery = window.jQuery;

function scrollActiveTabIntoView() {
    const headerHeight = $('#header').height() ? $('#header').height() : 0;
    const adminbarHeight = $('#wpadminbar').height() ? $('#wpadminbar').height() : 0;
    const activeTab = $('.post-filter__btn.active');

    if (activeTab.length) {
        const y = activeTab[0].getBoundingClientRect().top + window.scrollY - headerHeight - adminbarHeight;
        window.scrollTo({ top: y, behavior: 'smooth' });

        activeTab[0].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    }
}


// Filtering - Blogg + Nyheter
$(".post-filter .post-filter__btn").on("click", function (e) {
    e.preventDefault();

    /* Data */
    let postType = $(this).data("filter");
    let term = $(this).data("term");
    let taxonomy = $(this).data("taxonomy");
    let amountOfPosts = $(this).data("amountOfPosts");
    let showCurrentCatFirst = $(this).data("showCurrentCatFirst");
    let pageurl = $(this).attr("href");

    /* TODO: fix ajaxurl (instead of receiving it from btn attr) */
    let ajax_url = $(".post-filter").data("ajax-url");

    /* Prevent filter on active */
    if ($(this).hasClass("active")) {
        return;
    }

    /* Reset active */
    $(".post-filter__btn").removeClass("active");
    $("#load-more-posts").removeData("searchPostId");
    $("#load-more-posts").removeAttr("data-search-post-id");

    /* Adds active to current */
    $(this).addClass("active");

    $.ajax({
        url: ajax_url,
        type: "GET",
        data: {
            postType: postType,
            term: term,
            taxonomy: taxonomy,
            amountOfPosts: amountOfPosts,
            showCurrentCatFirst: showCurrentCatFirst,
        },
        success: function (response) {
            if (pageurl != window.location) {
                window.history.pushState({ path: pageurl }, "", pageurl);
            }
            if (response.html) {
                $(".post-filter .filter-body").html(response.html);
            } else {
                $(".post-filter .filter-body").html("<p>Inga resultat hittades</p>");
            }

            /* Reset ads_paged */
            ads_paged = 2;

            /* Add crossfunctionality to load more posts */
            if ($("#load-more-posts").length) {
                $("#load-more-posts").data("term", term);
                $("#load-more-posts").data("page", 2);
                $("#load-more-posts").text("Ladda mer");
            }
        },
        error: function (error) {
            $(".post-filter .filter-body").html("<p>Inga resultat hittades</p>");
        },
    });
});



let ads_paged = 2;

/* Load more posts */
$("#load-more-posts").on("click", function (e) {
    e.preventDefault();
    const button = jQuery("#load-more-posts");

    /* TODO: fix ajaxurl (instead of receiving it from btn attr) */
    let ajax_url = button.data("ajax-url");
    let term = button.data("term") || "all";
    let taxonomy = button.data("taxonomy");
    let postType = button.data("filter");
    let offset = button.data("offset");
    let paged = button.data("page");
    let totalLoaded = button.data("totalLoaded") || 0;
    let amountOfPosts = button.data("amountOfPosts");
    let showCurrentCatFirst = $(this).data("showCurrentCatFirst");
    let searchPostId = button.data("searchPostId");

    button.text("Laddar...");

    $.ajax({
        url: ajax_url,
        type: "GET",
        data: {
            postType: postType,
            offset: offset,
            term: term,
            taxonomy: taxonomy,
            paged: paged,
            ads_paged: ads_paged,
            amountOfPosts: amountOfPosts,
            showCurrentCatFirst: showCurrentCatFirst,
            searchPostId: searchPostId,
        },
        success: function (response) {
            if (response.html) {
                $(".post-filter .filter-body").append(response.html);
                button.data("totalLoaded", totalLoaded + response.totalLoaded);
                button.data("page", paged + 1);
                button.text("Ladda mer");

                // ads_paged++;
                ads_paged = response.ads_paged;
            } else {
                button.text("Inga fler inlägg");
            }
        },
        error: function (error) {
            button.text("Inga fler inlägg");
            button.removeClass("loading");
        },
    });
});

/* Open Blog Comments */
$("body").on("click", ".open-blog-comments", function (e) {
    e.preventDefault();
    $(this)
        .parent()
        .closest(".blog-comments-holder")
        .find(".comments-area")
        .slideToggle(200);
});

/* Footer collapse */
$("body").on("click", "footer .collapse-click", function (e) {
    e.preventDefault();
    if (window.innerWidth < 768) {
        $(this)
            .toggleClass("active")
            .parent()
            .closest(".footer-collapse")
            .find(".item")
            .slideToggle(150);
    }
});

/* FAQ Customer Service template */
$(".faq-target").on("click", function (e) {
    $(this).next(".faq-answer").slideToggle(200);
    $(this).parent().toggleClass("active");
});

$(window).on('load', function () {
    let categoryExistsInUrl = window.location.href.includes("?kategori");
    if (categoryExistsInUrl) {
        scrollActiveTabIntoView();
    }
});

/* Filter Customer Service template */
$(document).ready(function () {
    var hash = window.location.hash;
    if (hash) {
        var slug = hash.replace("#", "");
        jQuery(".cs-filter").each(function () {
            var dataSlug = jQuery(this).data("slug");
            if (
                dataSlug
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/å/g, "a")
                    .replace(/ä/g, "a")
                    .replace(/ö/g, "o") == slug
            ) {
                jQuery(this).click();
            }
        });
    } else {
        jQuery(".cs-filter").first().click();
    }
});

$(".cs-filter").on("click", function (e) {
    e.preventDefault();
    var index = $(this).index();
    $(".content-holder").hide();
    $(".content-holder").eq(index).show();
    $(".cs-filter").removeClass("active");
    $(this).addClass("active");

    /*
     ** Creates a hash in the URL.
     ** Onload functionality inside template.
     */
    var slug = $(this).data("slug");
    slug = slug
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/å/g, "a")
        .replace(/ä/g, "a")
        .replace(/ö/g, "o");
    var url = window.location.href;
    var newUrl = url.split("#")[0] + "#" + slug;
    window.location.href = newUrl;
});
